const listingCategoriesRoutingConfiguration = [
  {
    key: 'bike',
    pathParams: { type: 'bike' },
    categories: [
      { key: 'road', pathParams: { type: 'bike', category: 'road' } },
      { key: 'mountain', pathParams: { type: 'bike', category: 'mountain' } },
      { key: 'gravel', pathParams: { type: 'bike', category: 'gravel' } },
      { key: 'city', pathParams: { type: 'bike', category: 'city' } },
      { key: 'electric', pathParams: { type: 'bike', category: 'electric' } },
      { key: 'bmx_dirt', pathParams: { type: 'bike', category: 'bmx_dirt' } },
      { key: 'folding', pathParams: { type: 'bike', category: 'folding' } },
      { key: 'other', pathParams: { type: 'bike', category: 'other' } },
    ],
  },
  {
    key: 'parts',
    pathParams: { type: 'parts' },
    categories: [
      { key: 'frame', pathParams: { type: 'parts', category: 'frame' } },
      { key: 'suspension', pathParams: { type: 'parts', category: 'suspension' } },
      { key: 'drivetrain', pathParams: { type: 'parts', category: 'drivetrain' } },
      { key: 'brakes', pathParams: { type: 'parts', category: 'brakes' } },
      { key: 'wheels', pathParams: { type: 'parts', category: 'wheels' } },
      { key: 'tyres_tubes', pathParams: { type: 'parts', category: 'tyres_tubes' } },
      { key: 'cockpit', pathParams: { type: 'parts', category: 'cockpit' } },
      { key: 'seat', pathParams: { type: 'parts', category: 'seat' } },
    ],
  }
];

const listingCategoriesRoutingConfigurationNew = [
  {
    key: 'bike',
    pathParams: { type: 'bike' },
    categories: [
      { key: 'road', pathParams: { type: 'bike', category: 'road' } },
      { key: 'mountain', pathParams: { type: 'bike', category: 'mountain' } },
      { key: 'gravel', pathParams: { type: 'bike', category: 'gravel' } },
      { key: 'city', pathParams: { type: 'bike', category: 'city' } },
      { key: 'electric', pathParams: { type: 'bike', category: 'electric' } },
      { key: 'bmx_dirt', pathParams: { type: 'bike', category: 'bmx_dirt' } },
      { key: 'folding', pathParams: { type: 'bike', category: 'folding' } },
      { key: 'other', pathParams: { type: 'bike', category: 'other' } },
    ],
  },
  {
    key: 'parts',
    pathParams: { type: 'parts' },
    categories: [
      { key: 'frame', pathParams: { type: 'parts', category: 'frame' } },
      { key: 'suspension', pathParams: { type: 'parts', category: 'suspension' } },
      { key: 'drivetrain', pathParams: { type: 'parts', category: 'drivetrain' } },
      { key: 'brakes', pathParams: { type: 'parts', category: 'brakes' } },
      { key: 'wheels', pathParams: { type: 'parts', category: 'wheels' } },
      { key: 'tyres_tubes', pathParams: { type: 'parts', category: 'tyres_tubes' } },
      { key: 'cockpit', pathParams: { type: 'parts', category: 'cockpit' } },
      { key: 'seat', pathParams: { type: 'parts', category: 'seat' } },
    ],
  },
  {
    key: 'winter',
    pathParams: { type: 'winter' },
    categories: [
      { key: 'skis', pathParams: { type: 'winter', category: 'skis' } },
      { key: 'snowboard', pathParams: { type: 'winter', category: 'snowboard' } },
      { key: 'accessories', pathParams: { type: 'winter', category: 'accessories' } },
    ],
  },
];

export { listingCategoriesRoutingConfiguration, listingCategoriesRoutingConfigurationNew };
