import React, { Component } from 'react';

import classNames from 'classnames';
import { Field, Form as FinalForm } from 'react-final-form';
import css from './SectionSearch.module.css';

import { Form } from '../../../components';
import config from '../../../config';
import { injectIntl } from '../../../util/reactIntl';

import FilterIcon from '../../../assets/icons/filters-24.png';
import SearchIcon from '../../../assets/icons/search-grey.svg';

function KeywordSearchField(props) {
  const { keywordSearchWrapperClasses, iconClass, intl, isMobile, inputRef } = props;
  return (
    <div className={keywordSearchWrapperClasses}>
      <button className={css.searchSubmit}>
        <img src={SearchIcon} className={css.searchIcon} />
      </button>
      <Field
        name="keywords"
        render={({ input, meta }) => (
          <input
            className={isMobile ? css.mobileInput : css.desktopInput}
            {...input}
            id="keyword-search"
            ref={inputRef}
            type="text"
            placeholder={intl.formatMessage({
              id: 'top_bar.search_form.placeholder',
            })}
            autoComplete="off"
          />
        )}
      />
    </div>
  );
}

class SectionSearchComponent extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);

    this.searchInput = null;
    this.setSearchInputRef = element => {
      this.setSearchInput = element;
    };
  }

  onSubmit(values) {
    const { onSubmit } = this.props;
    onSubmit({ keywords: values.keywords });
    // blur search input to hide software keyboard
    this.searchInput?.blur();
  }

  render() {
    const { onSubmit, children, ...rest } = this.props;
    const submit = this.onSubmit;

    return (
      <FinalForm
        {...rest}
        onSubmit={submit}
        render={formRenderProps => {
          const { desktopInputRoot, intl, isMobile, handleSubmit, showFilters, onFilterOpen } = formRenderProps;

          const desktopInputRootClass = desktopInputRoot || css.desktopInputRoot;

          const keywordSearchWrapperClasses = classNames(
            css.keywordSearchWrapper,
            isMobile ? css.mobileInputRoot : desktopInputRootClass
          );

          return (
            <Form
              className={classNames(rest.rootClassName, css.root)}
              onSubmit={handleSubmit}
              enforcePagePreloadFor="SearchPage"
            >
              <KeywordSearchField
                keywordSearchWrapperClasses={keywordSearchWrapperClasses}
                iconClass={classNames(isMobile ? css.mobileIcon : css.desktopIcon || css.icon)}
                intl={intl}
                isMobile={isMobile}
                inputRef={this.setSearchInputRef}
              />
              {showFilters != undefined ? (
                <div className={css.dividerWrapper}>
                  <div className={css.verticalDivider} />
                </div>
              ) : null}
              {showFilters != undefined ? (
                <div className={css.buttons}>
                  <img className={css.filterIcon} src={FilterIcon} onClick={onFilterOpen} />
                </div>
              ) : null}
              {/* {children} */}
            </Form>
          );
        }}
      />
    );
  }
}

SectionSearchComponent.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobile: false,
  appConfig: config,
};

const SectionSearch = injectIntl(SectionSearchComponent);

export default SectionSearch;
